import { useRef, useState } from 'react';
import { useRouter } from 'next/router';
import {
  FloatingList,
  useFloating,
  flip,
  offset,
  autoUpdate,
  useListNavigation,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  useTransitionStyles,
} from '@floating-ui/react';
import clsx from 'clsx';

import { availableLocaleOptions } from './constants';
import { LocaleSelectorOption } from './LocaleSelectorOption';

export function LocaleSelector() {
  const router = useRouter();

  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const selectedIndex = Math.max(
    availableLocaleOptions.findIndex((item) => item.id === router.locale),
    0,
  );

  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom',
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [flip(), offset({ mainAxis: 6 })],
  });
  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    initial: {
      opacity: 0,
    },
    open: {
      opacity: 1,
    },
    close: {
      opacity: 0,
    },
    duration: 300,
  });

  const elementsRef = useRef([]);
  const labelsRef = useRef([]);

  const listNavigation = useListNavigation(context, {
    listRef: elementsRef,
    activeIndex,
    onNavigate: setActiveIndex,
    loop: true,
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'listbox' });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [listNavigation, click, dismiss, role],
  );

  return (
    <div className="my-[12px] mx-[16px]">
      <button
        type="button"
        ref={refs.setReference}
        className={clsx(
          'w-[40px] h-[40px]',
          'flex flex-row justify-center items-center',
          'text-[14px] leading-none text-white/75',
          'rounded-full',
        )}
        {...getReferenceProps({
          style: {
            border: '2px solid rgba(255, 255, 255, 0.5)',
          },
        })}
      >
        {availableLocaleOptions[selectedIndex]?.label}
      </button>

      {isMounted && (
        <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
          <div
            ref={refs.setFloating}
            style={{ ...floatingStyles, zIndex: 10 }}
            {...getFloatingProps()}
          >
            <div
              style={transitionStyles}
              className={clsx(
                'max-h-[200px]',
                'p-[8px]',
                'flex flex-col gap-[8px]',
                'bg-neutral-900',
                'rounded-[10px]',
                'shadow-md shadow-white/10',
              )}
            >
              {availableLocaleOptions.map((locale) => (
                <LocaleSelectorOption
                  key={locale.id}
                  label={locale.label}
                  activeIndex={activeIndex}
                  onSelect={() => {
                    // Take the current route and just change the locale
                    router.push(router.asPath, undefined, {
                      locale: locale.id,
                    });

                    setOpen(false);
                  }}
                  getItemProps={getItemProps}
                />
              ))}
            </div>
          </div>
        </FloatingList>
      )}
    </div>
  );
}
