import React from 'react';

import { SOCIAL_LINK_DISCORD } from '@constants/social';

import { DiscordIcon } from '@components/icons/DiscordIcon';
import { UserPlusIcon } from '@components/icons/UserPlusIcon';
import { RssIcon } from '@components/icons/RssIcon';
import { SectionTitle } from '@components/layout/Footer/shared/SectionTitle';
import { SectionLink } from '@components/layout/Footer/shared/SectionLink';

const items = [
  {
    id: 'account-create',
    href: '/sign-up',
    title: 'Create account',
    icon: UserPlusIcon,
  },
  {
    id: 'email-sub',
    href: '/notify-me',
    title: 'Newsletter',
    icon: RssIcon,
  },
  {
    id: 'discord',
    href: SOCIAL_LINK_DISCORD,
    external: true,
    title: 'Discord',
    icon: DiscordIcon,
  },
];

/**
 * @param {{
 *   className?: string;
 * }} props
 *
 * @returns {Element}
 */
export function JoinUs({ className }) {
  return (
    <section className={className}>
      <SectionTitle>Join Us</SectionTitle>

      <ul className="list-none p-0 flex flex-col gap-[12px]">
        {items.map(({ id, href, external, title, icon }) => (
          <li key={id} className="leading-none !m-0">
            <SectionLink
              href={href}
              external={external}
              title={title}
              icon={icon}
            />
          </li>
        ))}
      </ul>
    </section>
  );
}
