import React from 'react';
import { SectionTitle } from '@components/layout/Footer/shared/SectionTitle';
import { SectionLink } from '@components/layout/Footer/shared/SectionLink';

const items = [
  {
    id: 'reviews',
    href: '/reviews',
    title: 'See reviews',
  },
  {
    id: 'catalog',
    href: '/ai-girls',
    title: 'Explore AI girls',
  },
  {
    id: 'pricing',
    href: '/plans',
    title: 'Get more messages',
  },
  {
    id: 'terms',
    href: '/terms',
    title: 'Terms & Conditions',
  },
  {
    id: 'lang',
    href: '/choose-language',
    title: 'Choose language',
  },
];

/**
 * @param {{
 *   className?: string;
 * }} props
 *
 * @returns {Element}
 */
export function Explore({ className }) {
  return (
    <section className={className}>
      <SectionTitle>Explore</SectionTitle>

      <ul className="list-none p-0 flex flex-col gap-[12px]">
        {items.map(({ id, href, title }) => (
          <li key={id} className="leading-none !m-0">
            <SectionLink href={href} title={title} />
          </li>
        ))}
      </ul>
    </section>
  );
}
