import React from 'react';
import clsx from 'clsx';

import { LogoIcon } from '@components/icons/LogoIcon';

import { General } from './General';
import { LearnMore } from './LearnMore';
import { Explore } from './Explore';
import { JoinUs } from './JoinUs';

/**
 * @param {{
 *   className?: string;
 * }} props
 *
 * @returns {Element}
 */
export function Footer({ className }) {
  return (
    <footer className={clsx('pt-[48px]', '!mt-[120px]', className)}>
      <div
        className={clsx(
          'container',
          'grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4',
          'gap-y-[48px] gap-x-[24px] md:gap-x-[48px] lg:gap-x-[24px]',
        )}
      >
        <General />
        <Explore className="md:justify-self-center" />
        <LearnMore className="md:justify-self-center" />
        <JoinUs className="lg:justify-self-center" />
      </div>

      <div className="!mt-[48px] py-[32px] bg-[#0e0f11]">
        <div className="container flex flex-row justify-center items-center gap-[8px]">
          <LogoIcon size={20} />
          <p className="m-0 text-[14px] md:text-[16px]">
            AI Girlfriend WTF {new Date().getFullYear()}. All rights reserved
          </p>
        </div>
      </div>
    </footer>
  );
}
