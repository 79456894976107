export const availableLocaleOptions = [
  {
    id: 'en',
    label: 'EN',
  },
  {
    id: 'ru',
    label: 'RU',
  },
];
