import React from 'react';
import { SectionTitle } from '@components/layout/Footer/shared/SectionTitle';
import { SectionLink } from '@components/layout/Footer/shared/SectionLink';

const items = [
  {
    id: 'ai-gf-chat',
    href: '/ai-girlfriend-chat',
    title: 'AI Girlfriend Chat',
  },
  // {
  //   id: 'ai-chat',
  //   href: '/talk-dirty-ai',
  //   title: 'Talk Dirty AI',
  // },
  // {
  //   id: 'ai-sexting',
  //   href: '/ai-sexting',
  //   title: 'AI sexting',
  // },
  {
    id: 'yandere-ai',
    href: '/yandere-ai',
    title: 'Yandere AI',
  },
  {
    id: 'waifu',
    href: '/ai-waifu',
    title: 'AI Waifu',
  },
  {
    id: 'blog',
    href: '/blog',
    title: 'Blog',
  },
];

/**
 * @param {{
 *   className?: string;
 * }} props
 *
 * @returns {Element}
 */
export function LearnMore({ className }) {
  return (
    <section className={className}>
      <SectionTitle>Learn more</SectionTitle>

      <ul className="list-none p-0 flex flex-col gap-[12px]">
        {items.map(({ id, href, title }) => (
          <li key={id} className="leading-none !m-0">
            <SectionLink href={href} title={title} />
          </li>
        ))}
      </ul>
    </section>
  );
}
