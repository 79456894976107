import { useListItem } from '@floating-ui/react';
import clsx from 'clsx';

export function LocaleSelectorOption({
  label,
  activeIndex,
  onSelect,
  getItemProps,
}) {
  const { ref, index } = useListItem({ label });

  const isActive = index === activeIndex;

  return (
    <button
      type="button"
      ref={ref}
      tabIndex={isActive ? 0 : -1}
      className={clsx(
        'w-[40px] h-[40px]',
        'text-[14px] text-white',
        'border border-transparent rounded-full',
        'transition duration-500 ease-in-out',
      )}
      style={isActive ? { border: '2px solid rgba(255, 255, 255, 0.3)' } : {}}
      {...getItemProps({
        role: 'option',
        onClick: onSelect,
      })}
    >
      {label}
    </button>
  );
}
