import React from 'react';
import clsx from 'clsx';
import Link from 'next/link';

const Logo = ({ className, ...props }) => {
  const classes = clsx('brand', className);

  return (
    <div {...props} className={classes}>
      <div className="m-0">
        <Link href="/">
          <img src="/logo.png" alt="Logo" width={32} height={32} />
        </Link>
      </div>
    </div>
  );
};

export default Logo;
