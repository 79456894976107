import Link from 'next/link';
import clsx from 'clsx';

/**
 * @param {{
 *   href: string;
 *   external?: boolean;
 *   title: string;
 *   icon?: React.ComponentType;
 *   reserveIconSpace?: boolean;
 * }} props
 *
 * @returns {JSX.Element}
 */
export function SectionLink({
  href,
  external = false,
  title,
  icon: Icon,
  reserveIconSpace = false,
}) {
  return (
    <Link
      className={clsx(
        'flex flex-row items-center gap-[8px]',
        'text-[14px] md:text-[16px] leading-normal',
      )}
      href={href}
      prefetch={!external}
      target={external ? '_blank' : undefined}
      rel={external ? 'noopener noreferrer' : undefined}
    >
      {(!!Icon || reserveIconSpace) && (
        <span className="w-[20px] shrink-0">
          {!!Icon && <Icon color="#e54242" size={20} />}
        </span>
      )}
      {title}
    </Link>
  );
}
