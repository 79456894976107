import React from 'react';
import Link from 'next/link';
import clsx from 'clsx';

import { LogoIcon } from '@components/icons/LogoIcon';
import { EmailIcon } from '@components/icons/EmailIcon';

/**
 * @param {{
 *  className?: string
 * }} props
 *
 * @returns {Element}
 */
export function General({ className }) {
  return (
    <div
      className={clsx(
        'col-span-2 md:col-span-1 flex flex-col gap-[32px]',
        className,
      )}
    >
      <div>
        <div className="flex flex-row items-center gap-[8px] mb-[16px]">
          <LogoIcon size={20} />

          <h2 className="m-0 text-[18px] md:text-[20px] leading-tight md:leading-[32px] font-bold whitespace-nowrap">
            AI Girlfriend WTF
          </h2>
        </div>
        <p className="m-0 text-[14px] md:text-[16px] leading-normal font-normal">
          Discover the Fun Adventures and AI Rolepay
        </p>
      </div>

      <Link
        href="mailto:contact@aigirlfriend.wtf"
        className="flex flex-row items-center gap-[8px] text-[14px] md:text-[16px] leading-[18px]"
      >
        <span className="shrink-0">
          <EmailIcon size={20} color="#e54242" />
        </span>
        contact@aigirlfriend.wtf
      </Link>
    </div>
  );
}
